import React from "react"
import { navigate } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { calculatorStore } from "../../lib/store"
import { CalculatorLayout } from "../../components/calculator"
import SourcesLogos from "../../components/sources-logos"
import GuidanceDate from "../../components/guidance-date"

const agree = () => {
  // Recording agreement, although this does not affect behavior
  calculatorStore.set("tos_agree", true)
  navigate("/calculator/start", {
    replace: true,
    state: { updateScroll: false, anchor: "calculator-nav" },
  })
}

const Calculator = () => {
  return (
    <Layout>
      <SEO title={"Get Started!"} />
      <CalculatorLayout disableNav={true}>
        <div className="prose max-w-none mb-10">
          <h2>Get Started!</h2>
          <p>
            Before you begin, please note that this PPE Planning Tool is
            intended to be used as a general guide for businesses. The PPE needs
            of your particular business and employees may vary significantly and
            each business is responsible for making its own decisions about
            those needs. All users should consult the links provided herein for
            the latest official health information, as the PPE Planning Tool
            summaries may not accurately reflect the current content of those
            official health links or the latest updates thereto. The State of
            New Jersey and the New Jersey Economic Development Authority
            (collectively, the “State”) is not responsible for the performance
            or accuracy of the PPE Planning Tool. By using the PPE Planning
            Tool, the user does hereby release any and all claims, causes of
            action, and demands against the State that are derived from its use.
          </p>

          <p>
            <GuidanceDate />
          </p>
          <SourcesLogos />
          <p>
            Additionally, you should consider engaging with your Local
            Department of Health for relevant local guidance.
          </p>
        </div>

        <div className="flex flex-col items-center justify-center pb-10">
          <button className="btn btn-primary" onClick={agree}>
            I understand
          </button>
        </div>
      </CalculatorLayout>
    </Layout>
  )
}
export default Calculator
